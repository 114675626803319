import { NewsItem } from '@/models/NewsItem'
import httpClient from './httpClient'
import { metadataElements, openGraphElements } from './customElements'
const elements = [
  'title',
  'summary',
  'image',
  'alternate_text',
  'path',
  'date',
  'visible_on',
  'web_content_summary',
  'web_content_image',
  ...metadataElements,
  ...openGraphElements
]

const getLatestNews = (params?: { limit?: number; except?: string }) =>
  httpClient.get<{ [x: string]: NewsItem }>('', {
    params: {
      'system.type': 'news',
      'elements.path[neq]': params?.except,
      'elements.visible_on[contains]': 'web',
      elements: elements.join(','),
      order: 'elements.date[desc]',
      limit: params?.limit || 4
    }
  }).then(res => {
    if (Object.keys(res.data).length === 0) {
      throw new Error('not found')
    }
    return res
  })

const getNews = () =>
  httpClient.get<{ [x: string]: NewsItem }>('', {
    params: {
      'system.type': 'news',
      'elements.visible_on[contains]': 'web',
      elements: elements.join(','),
      order: 'elements.date[desc]'
    }
  })

const getNewsItem = (slug: string) =>
  httpClient.get<NewsItem>('', {
    params: {
      'system.type': 'news',
      'elements.path': slug,
      'elements.visible_on[contains]': 'web',
      limit: 1
    }
  }).then(res => {
    if (Object.keys(res.data).length === 0) {
      throw new Error('not found')
    }
    return res
  })

const getNewsItemMobile = (slug: string) =>
  httpClient.get<NewsItem>('', {
    params: {
      'system.type': 'news',
      'elements.path': slug,
      'elements.visible_on[contains]': 'app',
      elements: [
        ...elements,
        'body',
        'body_ckeditor',
        'app_content_body',
        'app_content_body_ckeditor',
        'app_content_image'
      ].join(','),
      limit: 1
    }
  }).then(res => {
    if (Object.keys(res.data).length === 0) {
      throw new Error('not found')
    }
    return res
  })

export { getNews, getNewsItem, getLatestNews, getNewsItemMobile }
