
import { computed, defineComponent, onMounted, onBeforeUnmount } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import CardScroll from '@/components/CardScroll.vue'
import NewsCard from '@/components/NewsCard.vue'
import { getNewsItemMobile } from '@/kentico-api/news.api'
import useSwrv from 'swrv/dist/use-swrv'
import CkeditorModule from '@/components/CkeditorModule.vue'

export default defineComponent({

  name: 'Show',
  components: { PageHeader, NewsCard, GenericError, CardScroll, CkeditorModule },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const newsItemSource = (slug: string) => getNewsItemMobile(slug)

    const { data: newsResponse, error: newsItemError } = useSwrv(
      () => props.slug,
      newsItemSource,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )
    const newsItem = computed(() => newsResponse.value?.data)
    // eslint-disable-next-line complexity
    const bodyCkeditor = computed(() => {
      if (!newsItem.value) return null

      if (
        newsItem.value.appContentBodyCkeditor &&
        newsItem.value.appContentBodyCkeditor !== '<p><br></p>') {
        return newsItem.value.appContentBodyCkeditor
      }
      if (
        newsItem.value.webContentBodyCkeditor &&
        newsItem.value.webContentBodyCkeditor !== '<p><br></p>') {
        return newsItem.value.webContentBodyCkeditor
      }
      return newsItem.value.bodyCkeditor
    })

    // eslint-disable-next-line complexity
    const body = computed(() => {
      if (!newsItem.value) return null

      if (
        newsItem.value.appContentBody &&
        newsItem.value.appContentBody !== '<p><br></p>'
      ) {
        return newsItem.value.appContentBody
      }
      if (
        newsItem.value.webContentBody &&
        newsItem.value.webContentBody !== '<p><br></p>'
      ) {
        return newsItem.value.webContentBody
      }
      return newsItem.value.body
    })

    onMounted(() => {
      document.body.classList.add('bg-transparent')
      const oneTrust = document.getElementById('onetrust-consent-sdk')
      if (oneTrust) {
        oneTrust.style.display = 'none'
      }
    })

    onBeforeUnmount(() => {
      document.body.classList.remove('bg-transparent')
      const oneTrust = document.getElementById('onetrust-consent-sdk')
      if (oneTrust) {
        oneTrust.style.display = 'block'
      }
    })

    return {
      body,
      bodyCkeditor,
      newsItemError
    }
  }
})
